import { Extension, Root } from 'joi';


export const JoiInitialsExtension = (joi: Root): Extension => ({
    type: 'initials',
    base: joi.object().keys({
        surname: joi.string().required(),
        name: joi.string().required(),
        patronymic: joi.string().optional().allow('', null),
    }),
    messages: {
        'inn.format': '{{#label}} invalid format',
    },
});
