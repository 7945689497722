import { Extension, Root } from 'joi';


export const JoiStringNumericExtension = (joi: Root): Extension => ({
    type: 'string',
    base: joi.string(),
    coerce: {
        from: 'number',
        method(value) {
            return { value: value.toString() };
        },
    },
});
