
export const normalizePhone = (number: string | number): any => {
    if (!number) {
        return null;
    }

    let tmp: any = String(number).match(/[0-9]/g);

    if (!tmp) {
        return null;
    }

    if (tmp.length === 10) {
        tmp = [ 7, ...tmp ];
    } else if (tmp.length === 11 && parseInt(tmp[0], 10) === 8) {
        tmp[0] = 7;
    }

    return tmp.join('');
};
