import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { axiosPrivate } from '@/libs/api-client/axiosPrivate';

export const useDownloadFile = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const download = useCallback(async (url, open = false, returnFile = false, returnBlob = false, silent = false): Promise<File | Blob | null> => {
        setIsLoading(true);
        try {
            const response = await axiosPrivate.get(url, {
                responseType: 'arraybuffer',
            });
            const { data, headers } = response;

            const blob = new Blob([ data ], { type: headers['content-type'] });

            if (returnBlob) {
                return blob;
            }

            if (returnFile) {
                return new File([ blob ], decodeURI(headers['file-name']), { type: headers['content-type'] });
            }

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.target = '_blank';
            if (!open) {
                link.download = decodeURI(headers['file-name']);
            }
            document.body.appendChild(link);
            link.click();
            // link.parentElement.removeChild(link);
        } catch (e) {
            if (!silent) {
                toast.error('Произошла ошибка при формировании файла');
            }
            setIsLoading(false);
            return null;
        } finally {
            setIsLoading(false);
        }
        return null;
    }, []);
    return { isLoading, download };
};
