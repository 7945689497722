import {
    Schema,
} from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { ExtendedJoi, Joi } from './joi';
import { joiRuLocale } from './joi-ru-locale';


export const joiSchema = (schema: (joi: ExtendedJoi) => Schema) => joiResolver(schema(Joi), {
    messages: joiRuLocale,
    abortEarly: false,
});