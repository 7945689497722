import { Extension, Root, StringSchema } from 'joi';
import { Validator } from 'finbox-ui-kit/libs/validator';
import { normalizePhone } from '@/utils/normalize-phone';


export interface PhoneSchema<TSchema = string> extends StringSchema<TSchema> {
    partial(): PhoneSchema
}


export const JoiPhoneExtension = (joi: Root): Extension => ({
    type: 'phone',
    base: joi.string(),

    coerce(value: string) {
        return { value: normalizePhone(value) };
    },
    validate(value, helpers) {
        if (helpers.schema.$_getFlag('partial')) {
            return { value };
        }
        const isValid = Validator.phone(value);
        if (isValid !== true) {
            const err = helpers.error('phone.format');
            err.message = `"${(helpers.state.path as any).join('.')}" ${isValid as string}`;
            return { value, errors: err };
        }
        return { value };
    },
    rules: {
        partial: {
            method() {
                return this.$_setFlag('partial', true);
            },
        },
    },
});
