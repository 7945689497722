import { Checkbox } from './checkbox';
import { TCheckboxComplex } from './checkbox.types';
import { CheckboxControlled } from './checkbox-controlled';


(Checkbox as unknown as TCheckboxComplex).Controlled = CheckboxControlled;
const CheckboxComplex: TCheckboxComplex = Checkbox as unknown as TCheckboxComplex;

export {
    CheckboxComplex as Checkbox,
    CheckboxControlled,
};