import { Extension, Root } from 'joi';
import { Validator } from 'finbox-ui-kit/libs/validator';


export const JoiInnExtension = (joi: Root): Extension => ({
    type: 'inn',
    base: joi.string(),
    messages: {
        'inn.format': '{{#label}} invalid format',
    },
    coerce(value: string) {
        return { value: value.trim() };
    },
    validate(value, helpers) {
        const isValid = Validator.inn(value);
        if (isValid !== true) {
            return { value, errors: helpers.error('inn.format') };
        }
        return { value };
    },
});
