import { useCallback, useEffect } from 'react';
import { TUIIconDefaultProps, TUIIconBrandProps } from './icon';
let sprite: HTMLElement;
const loaded: string[] = [];

export function useIconsManager() {
    useEffect(() => {
        const exists = document.getElementById('icons');
        if (exists) {
            sprite = exists;
            return;
        }
        sprite = document.createElement('SVG');
        sprite.id = 'icons';
        sprite.style.position = 'fixed';
        sprite.style.left = '-9999px';
        sprite.style.top = '-9999px';
        document.body.append(sprite);
    }, []);

    const loadIcon = useCallback(async (props: (TUIIconDefaultProps | TUIIconBrandProps)) => {
        const iconId = `icons-${ props.type }-${ props.name }`;

        const exists = sprite.querySelector(`#${iconId}`);
        if (exists) {
            return;
        }
        if (loaded.includes(iconId)) {
            return;
        }
        loaded.push(iconId);
        const response = await fetch(
            `/assets/images/icons/${ props.type }/${ props.name }.svg`,
            {
                headers: {
                    'Content-Type': 'image/svg+xml',
                },
            },
        );
        const imageText = (await response.text());
        const elem = new DOMParser().parseFromString(imageText, 'text/html').body.childNodes[0] as HTMLElement;
        elem.id = `icons-${ props.type }-${ props.name }`;
        sprite.appendChild(elem);
    }, []);

    return {
        loadIcon,
    }
}