import { DateSchema, Extension, Root } from 'joi';
import { DateTime } from 'luxon';


export interface LuxonSchema<TSchema = DateTime> extends DateSchema<TSchema> {
    dateOnly(): LuxonSchema;

    fromFormat(format: string, clearZone?: boolean): LuxonSchema;
}

export const JoiLuxonExtension = (joi: Root): Extension => ({
    type: 'luxon',
    base: joi.any(),
    messages: {
        'luxon.required': '{{#label}} должно быть заполнено',
        'luxon.format': `{{#label}} некорректная дата`,
        'luxon.min': `{{#label}} дата должна быть больше {{#date}}`,
    },
    prepare(value: string | DateTime, helpers) {
        if (typeof value === 'string') {
            const format: string = helpers.schema.$_getRule('fromFormat')?.args?.format;
            const clearZone: boolean = helpers.schema.$_getRule('fromFormat')?.args?.clearZone;

            if (format) {
                return {
                    value: DateTime.fromFormat(value, 'dd.MM.yyyy', clearZone ? { zone: 'UTC', setZone: false } : undefined),
                };
            }

            if (helpers.schema.$_getFlag('dateOnly')) {
                return { value: DateTime.fromFormat(value, 'yyyy-MM-dd', clearZone ? { zone: 'UTC', setZone: false } : undefined) };
            }

            return { value: DateTime.fromISO(value, clearZone ? { zone: 'UTC', setZone: false } : undefined) };
        }
        return { value };
    },
    coerce(value: DateTime) {
        return { value };
    },
    validate(value: DateTime, helpers) {
        if (!value) {
            return { errors: helpers.error('luxon.required') };
        }
        if (!value.isValid) {
            return { errors: helpers.error('luxon.format') };
        }
        return { value };
    },
    rules: {
        dateOnly: {
            method() {
                return this.$_setFlag('dateOnly', true);
            },
        },
        fromFormat: {
            convert: true,
            args: [
                {
                    name: 'format',
                    ref: true,
                    assert: (value) => typeof value === 'string',
                    message: 'fromFormat must be a string',
                },
                {
                    name: 'clearZone',
                    ref: true,
                    assert: (value) => value === undefined || typeof value === 'boolean',
                    message: 'clearZone must be a boolean',
                },
            ],
            method(format: string, clearZone: boolean) {
                return this.$_addRule({
                    name: 'fromFormat',
                    args: {
                        format,
                        clearZone,
                    },
                });
            },
        },
    },
});
