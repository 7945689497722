import { Extension, Root } from 'joi';
import { isDefined } from '@/utils/is-defined';



export const JoiRangeExtension = (joi: Root): Extension => ({
    type: 'range',
    base: joi.array(),
    messages: {
        'range.minLessThenMax': 'первое значение должно быть меньше или равно второму',
        'range.maxRequired': 'необходимо указать максимальное значение',
        'range.minRequired': 'необходимо указать минимальное значение',
    },
    validate(value: any[], helpers) {
        if (isDefined(value[0]) && isDefined(value[1])) {
            if (value[0] > value[1]) {
                return { value, errors: helpers.error('range.minLessThenMax') };
            }
        }
        if (isDefined(value[0]) && !isDefined(value[1])) {
            return { value, errors: helpers.error('range.maxRequired') };
        }
        if (!isDefined(value[0]) && isDefined(value[1])) {
            return { value, errors: helpers.error('range.minRequired') };
        }
        return { value };
    },
});
