import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import AppLayout from '@/app-layout';
import { ClientContextProvider } from '@/context/client.context';
import ErrorBoundary from '@/error-boundary';
import PageNotFound from '@/page-not-found';
import { ClientOrdersContextProvider } from '@/context/client-orders.context';
import { ClientTasksContextProvider } from '@/context/client-tasks.context';
import { ClientDocumentsContextProvider } from '@/context/client-documents.context';
import { useAuthContext } from '@/context/auth.context';
import { UserRole } from '@/consts';


const GarbagePage = React.lazy(() => import('@/components/garbage'));
const Clients = React.lazy(() => import('@/components/leads/containers/leads'));
const LeadPage = React.lazy(() => import('@/components/leads/containers/lead-page'));
const CreditorsOrdersPage = React.lazy(() => import('@/components/creditor-orders'));
const Settings = React.lazy(() => import('@/components/settings'));
const Contracts = React.lazy(() => import('@/components/contracts'));
const Calls = React.lazy(() => import('@/components/calls'));
const Reports = React.lazy(() => import('@/components/reports'));
// const Development = React.lazy(() => import('@/components/development'));
const Profile = React.lazy(() => import('@/components/profile'));
const SearchOrganization = React.lazy(() => import('@/components/search-organization'));
const RosreestrReports = React.lazy(() => import('@/components/rosreestr-reports'));
const Help = React.lazy(() => import('@/components/help'));
const ReportsAnalytics = React.lazy(() => import('@/components/analytics'));
const EgrnOrganizations = React.lazy(() => import('@/components/egrn-organizations'));
const ContractsControl = React.lazy(() => import('@/components/contracts-control'));
const ContractAgencyCommission = React.lazy(() => import('@/components/contracts-agency-rewards'));
const SignedDocuments = React.lazy(() => import('@/components/signed-documents'));
const Creditors = React.lazy(() => import('@/components/creditors'));
const PotentialCreditors = React.lazy(() => import('components/potential-creditor'));
const NumberToWords = React.lazy(() => import('components/number-to-words'));
const WikiAuth = React.lazy(() => import('components/wiki-auth'));
const Transits = React.lazy(() => import('components/transits'));
const BotCreditors = React.lazy(() => import('components/bot-creditors'));
const LoanCalculator = React.lazy(() => import('components/loan-calculator'));
const Finbox = React.lazy(() => import('components/finbox'));
const Approves = React.lazy(() => import('components/approves'));


const appRoutes = {
    indexClients: {
        path: '/',
        element: (
            <ClientContextProvider>
                <Navigate to='/clients' replace/>
            </ClientContextProvider>
        ),
    },
    indexAnalytics: {
        path: '/',
        element: (
            <ClientContextProvider>
                <Navigate to='/analytics' replace/>
            </ClientContextProvider>
        ),
    },
    indexEgrnOrganizations: {
        path: '/',
        element: (
            <ClientContextProvider>
                <Navigate to='/egrn-organizations' replace/>
            </ClientContextProvider>
        ),
    },
    indexContractsControl: {
        path: '/',
        element: (
            <ClientContextProvider>
                <Navigate to='/contracts-control' replace/>
            </ClientContextProvider>
        ),
    },
    clients: {
        path: 'clients/*',
        element: (
            <ClientContextProvider>
                <Clients/>
            </ClientContextProvider>
        ),
    },
    clientPage: {
        path: '/leads/:id/*',
        element: (
            <ClientContextProvider>
                <ClientOrdersContextProvider>
                    <ClientTasksContextProvider>
                        <ClientDocumentsContextProvider>
                            <LeadPage/>
                        </ClientDocumentsContextProvider>
                    </ClientTasksContextProvider>
                </ClientOrdersContextProvider>
            </ClientContextProvider>
        ),
    },
    analytics: {
        path: 'analytics',
        element: <ReportsAnalytics/>,
    },
    garbage: {
        path: 'garbage',
        element: <GarbagePage/>,
    },
    searchOrganization: {
        path: 'search-organization',
        element: <SearchOrganization/>,
    },
    creditorsOrders: {
        path: 'creditors_orders',
        element: <CreditorsOrdersPage/>,
    },
    settings: {
        path: 'settings/*',
        element: <Settings/>,
    },
    profile: {
        path: 'profile/*',
        element: <Profile/>,
    },
    reports: {
        path: 'reports/*',
        element: <Reports/>,
    },
    contracts: {
        path: 'contracts',
        element: <Contracts/>,
    },
    calls: {
        path: 'calls/*',
        element: <Calls/>,
    },
    rosreestrReports: {
        path: 'rosreestr-reports/*',
        element: <RosreestrReports/>,
    },
    help: {
        path: 'help/*',
        element: <Help/>,
    },
    egrnOrganizations: {
        path: 'egrn-organizations/*',
        element: <EgrnOrganizations/>,
    },
    contractsControl: {
        path: 'contracts-control',
        element: <ContractsControl/>,
    },
    contractsAgencyRewards: {
        path: 'contracts-agency-rewards',
        element: <ContractAgencyCommission/>,
    },
    signedDocuments: {
        path: 'signed-documents',
        element: <SignedDocuments/>,
    },
    creditors: {
        path: 'creditors/*',
        element: <Creditors/>,
    },
    potentialCreditors: {
        path: 'potential-creditors/*',
        element: <PotentialCreditors/>,
    },
    numberToWords: {
        path: 'number-to-words',
        element: <NumberToWords/>,
    },
    wikiAuth: {
        path: 'oauth/authorize',
        element: <WikiAuth/>,
    },
    transits: {
        path: 'transits/*',
        element: <Transits/>,
    },
    botCreditors: {
        path: 'bot-creditors/*',
        element: <BotCreditors/>,
    },
    loanCalculator: {
        path: 'loan-calculator',
        element: <LoanCalculator/>,
    },
    finbox: {
        path: 'finbox/*',
        element: <Finbox/>,
    },
    approves: {
        path: 'approves/*',
        element: <Approves/>,
    },
};

const rolesByRole = {
    [UserRole.admin]: [
        appRoutes.indexAnalytics,
        appRoutes.clients,
        appRoutes.clientPage,
        appRoutes.analytics,
        appRoutes.garbage,
        appRoutes.searchOrganization,
        appRoutes.creditorsOrders,
        appRoutes.settings,
        appRoutes.reports,
        appRoutes.contracts,
        appRoutes.calls,
        appRoutes.rosreestrReports,
        appRoutes.egrnOrganizations,
        appRoutes.contractsControl,
        appRoutes.contractsAgencyRewards,
        appRoutes.signedDocuments,
        appRoutes.creditors,
        appRoutes.potentialCreditors,
        appRoutes.profile,
        appRoutes.help,
        appRoutes.numberToWords,
        appRoutes.wikiAuth,
        appRoutes.transits,
        appRoutes.botCreditors,
        appRoutes.loanCalculator,
        appRoutes.finbox,
        appRoutes.approves,
    ],
    [UserRole.manager]: [
        appRoutes.indexClients,
        appRoutes.clients,
        appRoutes.clientPage,
        appRoutes.garbage,
        appRoutes.searchOrganization,
        appRoutes.analytics,
        appRoutes.settings,
        appRoutes.contracts,
        appRoutes.egrnOrganizations,
        appRoutes.rosreestrReports,
        appRoutes.reports,
        appRoutes.creditorsOrders,
        appRoutes.creditors,
        appRoutes.potentialCreditors,
        appRoutes.profile,
        appRoutes.help,
        appRoutes.contractsAgencyRewards,
        appRoutes.wikiAuth,
        appRoutes.approves,
    ],

    [UserRole.accountant]: [
        appRoutes.indexContractsControl,
        appRoutes.settings,
        appRoutes.signedDocuments,
        appRoutes.contractsControl,
        appRoutes.contractsAgencyRewards,
        appRoutes.creditors,
        appRoutes.profile,
        appRoutes.help,
        appRoutes.wikiAuth,
        appRoutes.numberToWords,
        appRoutes.searchOrganization,
    ],

    [UserRole.analytics]: [
        appRoutes.indexAnalytics,
        appRoutes.analytics,
        appRoutes.reports,
        appRoutes.profile,
        appRoutes.help,
        appRoutes.settings,
    ],
    [UserRole.creditors_manager]: [
        appRoutes.indexEgrnOrganizations,
        appRoutes.creditors,
        appRoutes.potentialCreditors,
        appRoutes.reports,
        appRoutes.egrnOrganizations,
        appRoutes.rosreestrReports,
        appRoutes.searchOrganization,
        appRoutes.contractsAgencyRewards,
        appRoutes.profile,
        appRoutes.help,
        appRoutes.wikiAuth,
        appRoutes.botCreditors,
        appRoutes.finbox,
        appRoutes.approves,
    ],
    [UserRole.additional]: [
        appRoutes.botCreditors,
    ],
    [UserRole.hr]: [
        appRoutes.indexAnalytics,
        appRoutes.profile,
        appRoutes.settings,
        appRoutes.analytics,
        appRoutes.reports,
        appRoutes.wikiAuth,
    ],
};

export function AppRoutes() {
    const { user } = useAuthContext();

    return useRoutes([
        {
            element: <AppLayout/>,
            errorElement: <ErrorBoundary/>,
            children: user?.roles?.reduce((acc, role) => [ ...acc, ...rolesByRole[role] ], []),
        },
        {
            path: '*',
            element: <AppLayout/>,
            children: [
                {
                    path: '*',
                    element: <PageNotFound/>,
                },
            ],
        },
    ]);
}
