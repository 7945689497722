import { Extension, Root, ObjectSchema } from 'joi';
import { round } from 'lodash';

export interface FileSchema<TSchema = ObjectSchema> extends ObjectSchema<TSchema> {
    /**
     * Max size in bytes
     * @param size
     */
    maxSize(size: number): FileSchema<TSchema>;
    mimeTypes(...mimeTypes: string[]): FileSchema<TSchema>;
}

export const JoiFileExtension = (joi: Root): Extension => ({
    type: 'file',
    base: joi.any(),
    messages: {
        'file.required': 'обязательное поле',
        'file.maxSize': 'файл должен быть не больше {{#maxSize}}Кб',
        'file.mimeType': 'некорректный формат файла. Допустимые форматы: {{#mimeTypesDef}}',
    },
    validate(value: File | undefined, helpers) {
        if (!value) {
            return { errors: helpers.error('file.required') };
        }
        const maxSize: number = helpers.schema.$_getRule('maxSize')?.args?.size;
        const mimeTypes: string[] = helpers.schema.$_getRule('mimeTypes')?.args?.mimeTypes;

        if (maxSize && value.size > maxSize) {
            return { errors: helpers.error('file.maxSize', { maxSize: round(maxSize / 1024, 1) }) };
        }

        if (mimeTypes && !mimeTypes.includes(value.type)) {
            console.log(value.type);
            return { errors: helpers.error('file.mimeType', { mimeTypesDef: mimeTypes.join(', ') }) };
        }

        return { value };
    },
    rules: {
        maxSize: {
            convert: true,
            args: [
                {
                    name: 'size',
                    ref: true,
                    assert: (value) => typeof value === 'number',
                    message: 'size must be a number',
                },
            ],
            method(size: number) {
                return this.$_addRule({
                    name: 'maxSize',
                    args: {
                        size,
                    },
                });
            },
        },
        mimeTypes: {
            convert: true,
            args: [
                {
                    name: 'mimeTypes',
                    ref: true,
                    // assert: (value) => Array.isArray(value),
                    message: 'mimeTypes must be array of strings',
                },
            ],
            method(...mimeTypes: string[]) {
                return this.$_addRule({
                    name: 'mimeTypes',
                    args: {
                        mimeTypes,
                    },
                });
            },
        },
    },
});
